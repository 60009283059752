import React from 'react'
import './common.css';
import footicon1 from "../../images/foot_icon1.svg";
import footicon2 from "../../images/foot_icon2.svg";
import footicon3 from "../../images/foot_icon3.svg";

const Footer = () => {
  return (
    <div className="footer">
      <section className="footer-bar">
        <div className="container">
          <div className="footer_linkmain">
            <div className="row">
              <div className="col-md-6">
                <div className="footerlogo_main">
                  <div className="footerlogo">
                    <a href="/">
                      <img
                        className="foot-logo"
                        src={require("../../images/logo-w.png")}
                      ></img>
                    </a>
                  </div>
                  <div class="contact_infos">
                    <span>
                      <img src={require("../../images/mail.png")}></img>
                    </span>
                    <a href="mailto:help.rista@dotpe.in">help.rista@dotpe.in</a>
                  </div>
                  <div class="contact_infos">
                    <span>
                      <img src={require("../../images/phone-call.png")}></img>
                    </span>
                    <a href="tel:8287120030">8287120030</a>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="footer_infoslinks">
                  <h3>Products</h3>
                  <ul>
                    <li>
                      <a href="/restaurant-point-of-sale-software">
                        Point of Sale
                      </a>
                    </li>
                    <li>
                      <a href="/restaurant-inventory-management">
                        Inventory Management
                      </a>
                    </li>
                    <li>
                      <a href="/restaurant-direct-ordering-system">
                        Direct Ordering
                      </a>
                    </li>
                    <li>
                      <a href="/whatsapp-business-api">WhatsApp for Business</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3">
                <div className="footer_infoslinks">
                  <h3>Resources</h3>
                  <ul>
                    {/* <li>
                      <a href="#">Blog</a>
                    </li> */}
                    <li>
                      <a href="/api/documentation">API Documentation</a>
                    </li>
                    <li>
                      <a
                        href="/downloads/RistaSalesInstaller.exe"
                        target="_blank"
                      >
                        Download Rista for Windows
                      </a>
                    </li>
                  
                    <li>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.ristaapps.business&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-AC-global-none-all-co-pr-py-PartBadges-Oct1515-1"
                        target="_blank"
                      >
                        Download Rista for Android
                      </a>
                    </li>
                    <li>
                      <a href="/faq">FAQs</a>
                    </li>
                    <li>
                      <a href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="/contactus">Contact Us</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="footer-bar-low">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="copyright_line">
                &copy; 2024 | All rights reserved by DotPe Pvt. Ltd.
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <ul>
                <li className="terms_mobile">
                  <a href="/termsconditions">Terms & Conditions</a>
                </li>
                <li className="terms_mobile">
                  <a href="/privacypolicy">Privacy Policy</a>
                </li>
                <li className="footerlogo_mobile">
                  <div className="footerlogo">
                    <a href="/">
                      <img
                        className="foot-logo"
                        src={require("../../images/logo-w.png")}
                      ></img>
                    </a>
                  </div>
                  <div class="contact_infos">
                    <span>
                      <img src={require("../../images/mail.png")}></img>
                    </span>
                    <a href="mailto:help.rista@dotpe.in">help.rista@dotpe.in</a>
                  </div>
                  <div class="contact_infos">
                    <span>
                      <img src={require("../../images/phone-call.png")}></img>
                    </span>
                    <a href="tel:8287120030">8287120030</a>
                  </div>
                </li>
                <li className="social-link">
                  <a href="https://x.com/DotPe_India" target="_blank">
                    <img src={footicon1} alt="Icon" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/dotpein/"
                    target="_blank"
                  >
                    <img src={footicon2} alt="Icon" />
                  </a>
                  <a
                    href="https://www.instagram.com/dotpe_india/"
                    target="_blank"
                  >
                    <img src={footicon3} alt="Icon" />
                  </a>
                  {/* <a href="#">
                    <img
                      className="foot-logo"
                      src={require("../../images/soc-2.png")}
                    ></img>
                  </a>
                  <a href="#">
                    <img
                      className="foot-logo"
                      src={require("../../images/soc-3.png")}
                    ></img>
                  </a> */}
                </li>

                <li className="termslink termslink_mobterm">
                  <a href="/termsconditions">Terms & Conditions</a>
                </li>
                <li className="termslink termslink2 termslink_mobterm">
                  <a href="/privacypolicy">Privacy Policy</a>
                </li>
              </ul>

              <div className="footer_centerterms">
                <a href="/termsconditions">Terms & Conditions</a>
                <a href="/privacypolicy">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="copyright_line copyright_line_mobile">
        &copy; 2024 | All rights reserved by DotPe Pvt. Ltd.
      </div>
    </div>
  );
}

export default Footer